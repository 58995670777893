export const PARTICLE_FRAGMENT = `
    uniform sampler2D pointTexture;
    uniform sampler2D pointTexture2;
    varying vec3 vColor;
    varying float vTextIndex;

    struct Sprites {
        sampler2D text;
    };

    uniform Sprites sprite[2];

    void main() {
        gl_FragColor = vec4( vColor, 1.0 );

       
        if(vTextIndex == 1.0) {
            gl_FragColor = gl_FragColor * texture2D( pointTexture, gl_PointCoord );
        } else {
            gl_FragColor = gl_FragColor * texture2D( pointTexture2, gl_PointCoord );
        }
    }
`;

export const SPRITESHEET_FRAGMENT = `
    uniform sampler2D texture1;
    uniform sampler2D texture2;
    uniform sampler2D texture3;
    
    uniform float cols;
    uniform float sprite;
    uniform float offsetPosition;
    uniform float offsetSprite;
    uniform float progress;
    uniform float opacity;

    varying vec2 vUv;
    varying float vSprite;
    
    void main() {
        float nTile = vSprite - 1.0;
        vec2 vCenter = vec2((mod(nTile, cols) * offsetPosition), 1.0 - floor(nTile / cols) * offsetPosition);

        vec2 newUVScale = (vUv - vCenter) * offsetSprite + vCenter;

        vec4 color1 = texture2D(texture1, newUVScale);
        vec4 color2 = texture2D(texture2, newUVScale);
        vec4 color3 = texture2D(texture3, newUVScale);
        vec4 mixedColor;

        // Interpola suavemente entre las texturas en función del valor de progress
        // if (progress <= .3) {
        //     mixedColor = color1;
        // } else if (progress <= 0.333 && progress > .3) {
        //     mixedColor = mix(color1, color2, (progress - .3) / (.333 - .3));
        // } else if (progress <= 0.6 && progress > .333) {
        //     mixedColor = color2;
        // } else if (progress <= 0.666 && progress > .6) {
        //     mixedColor = mix(color2, color3, (progress - .6) / (.666 - .6));
        // } else {
        //     mixedColor = color3;
        // }

        if (progress <= .25) {
            mixedColor = color1;
        } else if (progress <= 0.5) {
            mixedColor = color2;
        } else {
            mixedColor = color3;
        }

        // Ajusta la oscuridad basada en la coordenada Z
        // float near = -300.0;
        // float far = 400.0; 
        // float darkness = 1.0 - (gl_FragCoord.z * (far - near) + near) / far;
        // mixedColor.rgb *= darkness;

        gl_FragColor = vec4(mixedColor.rgb, mixedColor.a * opacity);
    }
`;
