import { Power2, Power3, gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(SplitText);

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import ScrollItem__Default from './ScrollItem__Default';
import GUIController from '../components/GUIController';
import { GetBy } from '../_app/cuchillo/core/Element';
import { GUIPanel } from '../components/GUIPanel';
import { Maths } from '../_app/cuchillo/utils/Maths';
import AvatarController from '../3D/AvatarController';
import { isSmartphone } from '../_app/cuchillo/core/Basics';
import { Shffl } from '../components/Shffl';

class ScrollItem__Avatars extends ScrollItem__Default {
  current = -1;
  scalar = 1;
  movement = 1;
  _slides = [];
  _info = [];

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    const slides = GetBy.class('__slide', __link);
    for (let i = 0; i < slides.length; i++) {
      this._slides.push(new Avatar__Item(slides[i]));
    }

    const gui = new GUIPanel(GetBy.id(__link.id + '_controls'), () => { this.next() }, () => { this.prev() });
    GUIController.registerGUI(__link.id, gui);

    this._call = () => { this.loop() };

    this.onShow = () => {
      this.showDefault();
      GUIController.show(this.item.id);
      gsap.ticker.add(this._call);
    };
    this.onMove = () => {
      if (this.progressInside < 0 && Scroll.direction === 1) GUIController.hide(this.item.id);
      else if (this.progressInside < 1 && Scroll.direction === 1) GUIController.show(this.item.id);
      else if (this.progressInside > 1 && Scroll.direction === -1) GUIController.hide(this.item.id);
      else if (this.progressInside > 0 && Scroll.direction === -1) GUIController.show(this.item.id);
    };
    this.onHide = () => {
      GUIController.hide(this.item.id);
      gsap.ticker.remove(this._call);
    };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================

  loop() {
    const p = Maths.map(this.progress, .1, .9, 0, 1);
    const currentRaw = Maths.map(p, 0, 1, 0, this._slides.length + 1);
    const current = Maths.clamp(Math.floor(currentRaw), 0, this._slides.length - 1);

    if (current !== this.current) {
      // Updates avatar current
      AvatarController.current = current;

      if (this.current >= 0) this._slides[this.current].hide();

      this.current = current;
      this._slides[this.current].show();

      GUIController.update(this.item.id, this._slides[this.current].info);
    }

    // Updates avatars progress
    AvatarController.progress = p;
  }

  next() {
    Scroll.gotoAvPag();
  }

  prev() {
    Scroll.gotoRePag();
  }

  resize() {
    super.resize();

    this.scalar = isSmartphone ? 4 : 1;
  }
}

Scroll._registerClass('SectionAvatars', ScrollItem__Avatars);

class Avatar__Item {
  dom;
  info;
  text;
  titles = [];
  itemsShffl = [];

  constructor(__d) {
    this.id = __d.dataset.id;
    this.dom = __d;

    if (isSmartphone) return;

    this.info = GetBy.class('__info', __d)[0].innerHTML;
    this.text = GetBy.class('__text', __d)[0];

    const title = GetBy.class('__title', __d)[0];
    this.titles = new SplitText(title, { type: 'words', tag: 'span' }).words;

    // gsap.set(this.titles, { y: '-70%', opacity: 0 });
    gsap.set(this.titles, { opacity: 0 });
    gsap.set(this.text, { opacity: 0 });

    this.setupShffl();
  }

  show() {
    // if (isSmartphone) return;

    gsap.killTweensOf([this.titles, this.text]);

    this.itemsShffl.map(item => { item.play(); })

    let delay = 0;
    for (let i = 0; i < this.titles.length; i++) {
      // delay += .055;

      // gsap.to(this.titles[i], {
      //   y: 0,
      //   duration: .7,
      //   ease: Power3.easeOut,
      //   force3D: true,
      //   delay
      // });

      gsap.to(this.titles[i], {
        opacity: 1,
        duration: .3,
        ease: Power2.easeOut,
        force3D: true,
        // delay: delay + .1
        delay: delay + .1
      });
    }

    if (this.text) {
      // delay += .4;
      delay += .2;
      gsap.to(this.text, {
        opacity: 1,
        duration: .3,
        ease: Power2.easeOut,
        delay
      });
    }
  }

  hide() {
    if (isSmartphone) return;

    // gsap.killTweensOf([this.titles, this.text]);

    // let delay = 0;
    // for (let i = 0; i < this.titles.length; i++) {
    //   delay += .025;

    //   gsap.to(this.titles[i], {
    //     opacity: 0,
    //     duration: .3,
    //     ease: Power3.easeOut,
    //     force3D: true,
    //     delay
    //   });
    // gsap.to(this.titles[i], {
    //   y: '-70%',
    //   duration: .7,
    //   ease: Power3.easeOut,
    //   force3D: true,
    //   delay: delay + .2
    // });
    // }

    // delay += .2;
    // gsap.to(this.text, {
    //   opacity: 0,
    //   duration: .2,
    //   ease: Power2.easeOut,
    //   delay
    // });
  }

  setupShffl() {
    [...GetBy.selector('[data-shffl-avatar]', this.dom)].map(item => {
      const shffl = new Shffl(item, {
        origin: Shffl.RANDOM,
        duration: Math.max(1.5, Math.min(2, item.innerText.length / 25)),
        textFrom: '',
        textTo: item.innerText
      });

      this.itemsShffl.push(shffl);
    });
  }
}
