import { GetBy } from '../_app/cuchillo/core/Element';

export default class GUIController {
    static guis = {};

    static init() { }

    static registerGUI(__id, __gui) {
        this.guis[__id] = __gui;
    }

    static show(__id) {
        this.guis[__id].show();
    }

    static hide(__id) {
        this.guis[__id].hide();
    }

    static update(__id, __val) {
        this.guis[__id].update(__val);
    }
}

export class GUI {
    item;
    visible = false;

    constructor(__item) {
        this.item = __item;
        this.cutAndPaste();
    }

    cutAndPaste() {
        GetBy.id('Controls').appendChild(this.item);
    }

    afterShow() {
        this.item.style.pointerEvents = 'initial';
    }

    update() { }

    show() { }

    hide() { }

    beforeHide() {
        this.item.style.pointerEvents = 'none';
    }

    directHide() {
        this.beforeHide();
    }
}
