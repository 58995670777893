import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import ScrollItem__Default from './ScrollItem__Default';
import AvatarController from '../3D/AvatarController';
import Scene from '../3D/Scene';
import { isSmartphone } from '../_app/cuchillo/core/Basics';

class ScrollItem__SectionCTA extends ScrollItem__Default {
  scalar = .3;
  movement = .3;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._call = () => { this.loop() };

    this.onShow = () => {
      this.showDefault();
      AvatarController.isLast = true;
    };
    this.onHide = () => {
      this.hideDefault();
      AvatarController.isLast = false;
      Scene.toggleSection(0);
    };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================


  resize() {
    super.resize();

    this.scalar = isSmartphone ? 1.2 : .3;
  }
}

Scroll._registerClass('SectionCTA', ScrollItem__SectionCTA);
