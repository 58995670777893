import * as THREE from 'three';
import { isWebpSupported } from '../../_app/cuchillo/core/Basics';
import { C, GetBy } from '../../_app/cuchillo/core/Element';
import { Metrics } from '../../_app/cuchillo/core/Metrics';
import { TEXTURE_LOADER } from '../constants';

export default class SpriteSheetGenerator {
  static canvas;
  static ctx;
  static hasSpritesheet = true;
  static cont = -1;
  static textures = [];
  static img;
  static call;
  static data;
  static options;
  static position = { x: 0, y: 0 };
  static limits = {
    cols: 0,
    rows: 0
  }
  static defaults = {
    container: document.body,
    id: 'SpriteSheetTexture',
    width: 4000,
    height: 4000,
    size: 200,
  }

  static init(__opts) {
    this.options = {
      ...this.defaults,
      ...__opts
    };
  }

  static start(__data, __call) {
    this.data = __data;
    this.call = __call;

    this.loadTexture();
  }

  static loadTexture() {
    const urlSignature = GetBy.id('__spritesheet-signature').getAttribute('href');
    const urlSeasonal = GetBy.id('__spritesheet-seasonal').getAttribute('href');
    const urlCustom = GetBy.id('__spritesheet-custom').getAttribute('href');
    const spriteSignature = !isWebpSupported ? urlSignature.replace('.webp', '') : urlSignature;
    const spriteSeasonal = !isWebpSupported ? urlSeasonal.replace('.webp', '') : urlSeasonal;
    const spriteCustom = !isWebpSupported ? urlCustom.replace('.webp', '') : urlCustom;

    this.textures[0] = TEXTURE_LOADER.load(spriteSignature);
    this.textures[1] = TEXTURE_LOADER.load(spriteSeasonal);
    this.textures[2] = TEXTURE_LOADER.load(spriteCustom);

    Promise.all(this.textures).then(() => {
      this.call();
    });
  }

  static dispose() {
    if (this.canvas) C.remove(this.canvas);

    this.ctx = null;
    this.cont = null;
    this.img = null;
    this.data = null;
    this.options = null;
    this.position = null;
    this.limits = null;
    this.defaults = null;
    this.call = null;
  }

  static resize() {
    this.limits.x = Math.floor(this.options.width / this.options.size);
    this.limits.y = Math.floor(this.options.height / this.options.size);
    this.canvas.setAttribute('width', this.options.width);
    this.canvas.setAttribute('height', this.options.height);
  }
}