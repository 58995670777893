// import gsap from 'gsap';
import { Group, Vector3, Vector2 } from 'three';

import WebGLSketch from "../_app/cuchillo/3D/WebGLSketch";
import { isSmartphone, isTouch } from '../_app/cuchillo/core/Basics';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Interaction } from '../_app/cuchillo/core/Interaction';
import { CENTER, SETTINGS } from './constants';

export default class SeedtagSketch extends WebGLSketch {
    cameraPosition = new Vector3();
    cameraLookAt = new Vector3();
    cameraLookAtTarget = new Vector3();
    group = new Group();
    cameraGroup = new Group();
    mouse = new Vector2();

    constructor(opts = {}) {
        super(opts);

        // RESETS CAMERA
        if (!this.controls.enabled) {
            this.camera.position.copy(CENTER);

            this.cameraPosition.copy(this.defaults.cameraPos);
            this.cameraGroup.position.copy(this.cameraPosition);
            this.cameraGroup.add(this.camera);

            this.cameraLookAt.copy(this.defaults.lookAt);
            this.cameraLookAtTarget.copy(this.defaults.lookAt);
            this.camera.lookAt(this.defaults.lookAt);
        }

        this.scene.add(this.group);
        this.scene.add(this.cameraGroup);
    }

    update() {
        super.update();

        if (SETTINGS.controls) {
            this.cameraGroup.position.copy(SETTINGS.cameraPos);
            return;
        }

        if (!isTouch) {
            // Mouse
            const center = {
                x: Interaction.positions.mouse.x - Metrics.CENTER_X,
                y: Interaction.positions.mouse.y - Metrics.CENTER_Y
            };
            // Group rotation
            const mx = Maths.map(center.x, - Metrics.CENTER_X, Metrics.CENTER_X, -SETTINGS.mouseX, SETTINGS.mouseX);
            const my = - Maths.map(center.y, - Metrics.CENTER_Y, Metrics.CENTER_Y, -SETTINGS.mouseY, SETTINGS.mouseY);
            this.mouse.x = Maths.lerp(this.mouse.x, mx, SETTINGS.mouseLerp);
            this.mouse.y = Maths.lerp(this.mouse.y, my, SETTINGS.mouseLerp);

            const rotationY = Maths.toRadians(this.mouse.x);
            const rotationX = - Maths.toRadians(this.mouse.y);

            if (SETTINGS.groupRotation) {
                this.group.rotation.x = rotationX;
                this.group.rotation.y = rotationY;
            }

            if (SETTINGS.cameraRotation) {
                this.cameraPosition.x += rotationX;
                this.cameraPosition.y += rotationY;
            }
        }

        this.cameraGroup.position.lerp(this.cameraPosition, SETTINGS.cameraLerp);

        this.cameraLookAt.lerp(this.cameraLookAtTarget, SETTINGS.cameraLerp);
        this.camera.lookAt(this.cameraLookAt);
    }

    resize() {
        if (isSmartphone) return;

        this.domElement.style = "";
        const { width, height } = this.domElement.getBoundingClientRect();

        if (width === this.size.x && height === this.size.y) return;

        this.size.set(width, height);
        this.renderer.setSize(this.size.x, this.size.y);

        this.camera.aspect = this.size.x / this.size.y;
        this.camera.updateProjectionMatrix();
    }
}
