import { gsap, Power3, Power2 } from 'gsap';
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(SplitText);

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
// import { Functions } from '../_app/cuchillo/utils/Functions';
import { Shffl } from '../components/Shffl';
import Scene from '../3D/Scene';
// import { LOOKAT_POSITIONS, POSITIONS } from '../3D/constants';
import AvatarController from '../3D/AvatarController';
import { isSmartphone } from '../_app/cuchillo/core/Basics';

export default class ScrollItem__Default extends VScroll_Item {
  itemsShffl = [];
  timeline;
  position = 0;
  scalar = .5;
  movement = .15;
  titles;
  text;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    if (__link.dataset.position !== undefined) this.position = parseInt(__link.dataset.position);

    this.onShow = () => { this.showDefault() };
    this.onHide = () => { this.hideDefault() };

    this.setupShffl();
    this.setupTitles();
  }

  setupShffl() {
    [...GetBy.selector('[data-shffl]', this.item)].map(item => {
      const shffl = new Shffl(item, {
        origin: Shffl.RANDOM,
        duration: Math.max(1.5, Math.min(2, item.innerText.length / 25)),
        textFrom: '',
        textTo: item.innerText
      });

      this.itemsShffl.push(shffl);
    });
  }

  setupTitles() {
    this.titles = new SplitText(GetBy.selector('[data-words]', this.item), { type: 'words', tag: 'span' }).words;
    this.text = GetBy.class('__text', this.item);

    this.timeline = new gsap.timeline();
    this.timeline.pause();

    let delay = 0;
    for (let i = 0; i < this.titles.length; i++) {
      delay += .055;
      this.timeline.from(this.titles[i], {
        y: '-70%',
        duration: .9,
        ease: Power3.easeOut,
        force3D: true
      }, delay);

      this.timeline.from(this.titles[i], {
        opacity: 0,
        duration: .3,
        ease: Power2.easeOut,
        force3D: true
      }, delay + .3);
    }

    if (this.text.length > 0) {
      delay += .4;
      this.timeline.from(this.text, {
        opacity: 0,
        duration: .5,
        ease: Power2.easeOut,
        force3D: true
      }, delay);
    }
  }

  showDefault() {
    // console.log('Show default', this.id);
    this.itemsShffl.map(item => { item.play(); })
    this.timeline.play();

    Scene.toggleSection(this.position);
    AvatarController.scalar = this.scalar;
    AvatarController.movement = this.movement;
  }

  hideDefault() {
    this.itemsShffl.map(item => { item.end(); })
  }

  resize() {
    super.resize();

    this.scalar = isSmartphone ? 2 : .5;
  }
}

Scroll._registerClass('SectionDefault', ScrollItem__Default);
