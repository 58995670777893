import { Vector3 } from 'three';

import WebGLObject from '../_app/cuchillo/3D/WebGLObject';
import { GetBy } from '../_app/cuchillo/core/Element';
import Scene from './Scene';
import { BG_MATERIAL, BG_GEOMETRY, SETTINGS } from './constants';
import { Maths } from '../_app/cuchillo/utils/Maths';

export default class BackgroundController {
    static bg;
    static factor = 10;

    static init() {
        if (this.initialized) return;

        const bg = GetBy.id('scene-bg');
        const s = bg.offsetWidth * this.factor;
        const size = new Vector3(s, s, s);

        this.bg = new WebGLObject({
            geometry: BG_GEOMETRY,
            material: BG_MATERIAL,
            size,
            target: bg
        });

        Scene.getCamera().add(this.bg);

        this.bg.init();
        this.bg.active = true;
    }

    static start() { }

    static stop() { }

    static loop() {
        this.bg.material.uniforms.time.value = Scene.getTime() * SETTINGS.time;

        this.bg.material.uniforms.color1.value.set(SETTINGS.color1);
        this.bg.material.uniforms.color2.value.set(SETTINGS.color2);
        this.bg.material.uniforms.color3.value.set(SETTINGS.color3);
        this.bg.material.uniforms.angle.value = Maths.toRadians(SETTINGS.angle);

        this.bg.material.uniforms.noiseIntensity.value = SETTINGS.noiseIntensity;
        this.bg.material.uniforms.linesPattern1.value = SETTINGS.linesPattern1;
        this.bg.material.uniforms.linesPattern2.value = SETTINGS.linesPattern2;
        this.bg.material.uniforms.linesIntensity.value = SETTINGS.linesIntensity;

        this.bg.update();
    }

    static resize() {
        const s = this.bg.dom.offsetWidth * this.factor;
        this.bg.resize(s, s, s);
    }

    static dispose() {
        Scene.removeItem(this.bg);
        this.bg.dispose();
    }
}
