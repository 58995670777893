import { Videos } from '../_app/cuchillo/components/Videos';
import { Acordions } from '../_app/cuchillo/components/Acordions';
import { Basics, isDebug, isTouch } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Preloader from '../layout/Preloader';
import Wrap from '../layout/Wrap';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import Scene from '../3D/Scene';
import BackgroundController from '../3D/BackgroundController';
import AvatarController from '../3D/AvatarController';
import ScrollbarCircle from '../scroll/ScrollbarCircle';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Shffl } from '../components/Shffl';

export default class Default extends Page {
  hovers = [];

  constructor() {
    super();

    Videos.init();
    Acordions.init();

    this.setShffleBtns();
  }

  //SHOW
  beforeShow() {
    super.beforeShow();

    Scroll.init(Scroll.AXIS_Y, { domResize: this.container, smooth: !isTouch, multiplicator: 1 });
    Scroll.setScrollbar(new ScrollbarCircle());

    Keyboard.mountPage(this.id);

    if (this.isFirstTime) {
      Scene.start();
      BackgroundController.start();
      AvatarController.start();
    }
  }

  setShffleBtns() {
    const btns = GetBy.class('__btn');

    this._hoverIn = (e) => this.hovers[e.target.dataset.index].play();
    this._hoverOut = (e) => this.hovers[e.target.dataset.index].reverse();

    for (let i = 0; i < btns.length; i++) {
      const btn = btns[i];
      btn.dataset.index = i;

      const target = GetBy.selector('span', btn)[0];

      const shffl = new Shffl(target, {
        origin: Shffl.RANDOM,
        duration: .8,
        textFrom: target.innerText,
        textTo: target.innerText
      });

      this.hovers.push(shffl);
      btn.addEventListener(Basics.mouseOver, this._hoverIn);
      btn.addEventListener(Basics.mouseOut, this._hoverOut);
    }
  }

  show__effect(__call) {
    if (isDebug) {
      Preloader.directHide();
      Wrap.show(() => { this.afterShow() })
    } else if (this.isFirstTime) {
      Wrap.show(() => {
        Preloader.hide(() => { this.afterShow() });
      });
    } else {
      Wrap.show(() => { this.afterShow() });
    }
  }

  afterShow() {
    super.afterShow();
    Scroll.start();
    Scroll.show();
  }

  //HIDE
  beforeHide() {
    Keyboard.unmountPage(this.id);
    super.beforeHide();
  }

  hide__effect() {
    Scroll.hide();
    Wrap.hide(() => { this.afterHide(); });
  }

  afterHide() {
    Scroll.dispose();
    super.afterHide();
  }

  //RESIZE
  resize() {
    super.resize();
    Acordions.resize();

    BackgroundController.resize();
    AvatarController.resize();
  }

  //LOOP
  loop() {
    if (this._isActive) {
      super.loop();
    }
  }
}

ControllerPage._addPage('default', Default);