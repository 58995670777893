import { Pane } from 'tweakpane';

import { isSmartphone } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import AvatarController from './AvatarController';
import Scene from './Scene';
import { LOOKAT_POSITIONS, POSITIONS, ROTATIONS, SETTINGS } from './constants';

export default class DebugPane {
    static item;
    static pane;
    static uniforms_image;

    static init(__item) {
        this.item = __item;
        this.pane = new Pane({ title: 'Ooooops!', expanded: true });

        this.setupBackground();
        this.setupMouse();
        this.setupCamera();
        // this.setupToggleCamera();
        // this.setupTextures(__item.defaults);
        // this.setupGeometry(__item);
        this.setupAnimation(__item.defaults.animation, () => { __item.reset(); });
        this.setupContainer(__item.defaults.container, () => { __item.reset(); });
        this.setupParticles(__item.defaults.particles, () => { __item.reset(); });
        this.setupAxis(__item.defaults.x, "X");
        this.setupAxis(__item.defaults.y, "Y");
        this.setupAxis(__item.defaults.z, "Z");
        this.setupAxis(__item.defaults.scale, "Scale");

        if (isSmartphone) {
            const holder = GetBy.class("tp-dfwv")[0];
            holder.style.top = "auto";
            holder.style.bottom = "8px";
        }
    }

    static setupBackground() {
        const bg = this.pane.addFolder({
            title: 'Background',
            expanded: false
        })
        bg.addInput(SETTINGS, 'color1', { label: 'Color 1' });
        bg.addInput(SETTINGS, 'color2', { label: 'Color 2' });
        bg.addInput(SETTINGS, 'color3', { label: 'Color 3' });
        bg.addInput(SETTINGS, 'time', {
            label: 'Time',
            step: .0001,
            min: 0,
            max: 5,
        });
        bg.addInput(SETTINGS, 'angle', {
            label: 'Angle',
            step: 1,
            min: 0,
            max: 365,
        });

        bg.addInput(SETTINGS, 'noiseIntensity', {
            label: 'Noise Intensity',
            step: .001,
            min: 0,
            max: 2,
        });
        bg.addInput(SETTINGS, 'linesPattern1', {
            label: 'Pattern 1 strength',
            step: .001,
            min: 0,
            max: 2,
        });
        bg.addInput(SETTINGS, 'linesPattern2', {
            label: 'Pattern 2 strength',
            step: .001,
            min: 0,
            max: 2,
        });
        bg.addInput(SETTINGS, 'linesIntensity', {
            label: 'Lines Size',
            step: .001,
            min: 0,
            max: 100,
        });
    }

    static setupMouse() {
        const mouse = this.pane.addFolder({
            title: 'Mouse',
            expanded: false
        });
        mouse.addInput(SETTINGS, 'mouseX', {
            label: 'Mouse X',
            step: .01,
            min: 0,
            max: 10,
        });
        mouse.addInput(SETTINGS, 'mouseY', {
            label: 'Mouse Y',
            step: .01,
            min: 0,
            max: 10,
        });
        mouse.addInput(SETTINGS, 'mouseLerp', {
            label: 'Mouse Lerp',
            step: .0001,
            min: 0,
            max: 1,
        });
        mouse.addInput(SETTINGS, 'cameraLerp', {
            label: 'Camera Lerp',
            step: .0001,
            min: 0,
            max: 1,
        });
        mouse.addInput(SETTINGS, 'groupRotation', {
            label: 'Grup Rotation'
        });
        mouse.addInput(SETTINGS, 'cameraRotation', {
            label: 'Camera Rotation'
        });
    }

    static setupCamera() {
        const camera = this.pane.addFolder({
            title: 'Camera',
            expanded: false
        });
        camera.addInput(SETTINGS, 'controls', {
            label: 'Controls enabled'
        });
        camera.addInput(SETTINGS, 'cameraPos', {
            label: 'Camera position'
        });
        camera.addInput(SETTINGS, 'cameraLerp', {
            label: 'Camera Lerp',
            step: .001,
            min: 0,
            max: 1,
        });
        camera.addInput(SETTINGS, 'cameraLookAtLerp', {
            label: 'Camera LookAt Lerp',
            step: .001,
            min: 0,
            max: 1,
        });
    }

    static setupGeometry(__data) {
        const subpane = this.pane.addFolder({ title: 'Geometry', expanded: false });

        subpane.addInput(__data, 'current', {
            label: 'Geometry selector',
            step: 1,
            min: 0,
            max: 2,
        });
    }

    static setupAnimation(__data) {
        const subpane = this.pane.addFolder({ title: 'Animation', expanded: false });

        subpane.addInput(__data, 'speed', {
            label: 'Speed',
            step: .001,
            min: -.1,
            max: .1,
        });
        subpane.addInput(__data, 'finePosition', {
            label: 'Fine',
            step: .01,
            min: 0,
            max: 100,
        });

        subpane.addInput(__data, 'gridSize', {
            label: 'Grid Size',
            step: 1,
            min: 1,
            max: 100,
        });
    }

    static setupParticles(__data, __call) {
        const subpane = this.pane.addFolder({ title: 'Particles', expanded: false });
        const params = {
            total: __data.total
        }

        subpane.addInput(params, 'total', {
            label: 'Total',
            step: 100,
            min: 100,
            max: 50000,
        }).on('change', (ev) => {
            if (ev.last) {
                __data.total = params.total;
                if (__call) __call();
            }
        });

        subpane.addInput(__data, 'size', {
            label: 'Size',
            step: .1,
            min: .1,
            max: 100,
        });
    }

    static setupContainer(__data, __call) {
        const subpane = this.pane.addFolder({ title: 'Box', expanded: false });

        subpane.addInput(__data, 'scale', {
            label: 'Size',
            step: .01,
            min: 1,
            max: 1000,
        }).on('change', (ev) => {
            if (ev.last) {
                if (__call) __call();
            }
        });

        // subpane.addInput(__data, 'scalar', {
        //     label: 'Pixel Size',
        //     step: .001,
        //     min: 0,
        //     max: 1
        // });
    }

    static setupAxis(__data, __title) {
        const subpane = this.pane.addFolder({ title: __title, expanded: false });

        const step = __title === "Scale" ? 0.01 : .1;
        const max = __title === "Scale" ? 100 : 1000;

        subpane.addInput(__data, 'force', {
            label: 'Force',
            step: step,
            min: 0,
            max: max
        });

        subpane.addInput(__data, 'amplitude', {
            label: 'Amplitude',
            step: 1,
            min: 1,
            max: 1300
        });

        subpane.addInput(__data, 'period', {
            label: 'Period',
            step: 1,
            min: 1,
            max: 50000
        });

        subpane.addInput(__data, 'z_dif', {
            label: 'Z Force',
            step: .001,
            min: 0,
            max: 1
        });
    }

    // static setupObject(obj, __call) {
    //     const subpane = this.pane.addFolder({ title: 'Object 3D', expanded: false });


    //     const params = {
    //         scale: obj.scale.x
    //     }

    //     subpane.addInput(obj.material, 'visible');

    //     subpane.addInput(params, 'scale', {
    //         label: 'Scale',
    //         step: .001,
    //         min: 1,
    //         max: 100,
    //     }).on('change', (ev) => {
    //         obj.scale.x = params.scale;
    //         obj.scale.y = params.scale;
    //         obj.scale.z = params.scale;

    //         if (ev.last) {
    //             if (__call) __call();
    //         }
    //     });

    //     subpane.addInput(obj.position, 'x', {
    //         label: 'Pos x',
    //         step: .1,
    //         min: -1000,
    //         max: 1000,
    //     });

    //     subpane.addInput(obj.position, 'y', {
    //         label: 'Pos y',
    //         step: .1,
    //         min: -1000,
    //         max: 1000,
    //     });

    //     subpane.addInput(obj.position, 'z', {
    //         label: 'Pos z',
    //         step: .1,
    //         min: -1000,
    //         max: 1000,
    //     });
    // }
}