import gsap, { Power2 } from 'gsap';

import { Basics } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { GUI } from '../components/GUIController';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';

export class GUISlider extends GUI {
    _slider;

    constructor(__item, __slider) {
        super(__item);

        this._slider = __slider;

        this._prev = () => { this.prev() };
        this.prevBtn = GetBy.selector('[data-prev]', __item)[0];
        this.prevBtn.addEventListener(Basics.clickEvent, this._prev);

        this._next = () => { this.next() }
        this.nextBtn = GetBy.selector('[data-next]', __item)[0];
        this.nextBtn.addEventListener(Basics.clickEvent, this._next);

        this.directHide();
    }

    show() {
        if (this.visible) return;
        this.visible = true;

        gsap.killTweensOf([this.prevBtn, this.nextBtn]);
        gsap.to(this.prevBtn, { opacity: 1, duration: .2, ease: Power2.easeOut });
        gsap.to(this.nextBtn, { opacity: 1, duration: .2, delay: .1, ease: Power2.easeOut, onComplete: () => { this.afterShow(); } });
    }

    hide() {
        if (!this.visible) return;
        this.visible = false;

        this.beforeHide();

        gsap.killTweensOf([this.prevBtn, this.nextBtn]);
        gsap.to(this.prevBtn, { opacity: 0, duration: .2, ease: Power2.easeOut });
        gsap.to(this.nextBtn, { opacity: 0, duration: .2, delay: .1, ease: Power2.easeOut });
    }

    directHide() {
        super.directHide();

        gsap.killTweensOf([this.prevBtn, this.nextBtn]);
        gsap.set([this.prevBtn, this.nextBtn], { opacity: 0 });
    }

    next() {
        let anchor = GetBy.id(`slide_${this._slider.actual + 1}`);
        if (anchor) Scroll.goto(anchor.parentNode.offsetTop + anchor.offsetTop);
    }

    prev() {
        let anchor = GetBy.id(`slide_${this._slider.actual - 1}`);
        if (anchor) Scroll.goto(anchor.parentNode.offsetTop + anchor.offsetTop);
    }

    dispose() {
        this.prevBtn.removeEventListener(Basics.clickEvent, this._prev);
        this.nextBtn.removeEventListener(Basics.clickEvent, this._next);
    }
}
