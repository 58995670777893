import gsap from 'gsap';

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { SliderHorizontalScroll } from '../_app/cuchillo/components/SliderHorizontalScroll';
import { isSmartphone, isTouch } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import GUIController from '../components/GUIController';
import { GUISlider } from '../components/GUISlider';
import ScrollItem__Default from './ScrollItem__Default';

class ScrollItem__SectionBenefits extends ScrollItem__Default {
  _slider;
  _slides = [];
  _thumbnails = [];
  hasMove = true;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._slides = GetBy.class('__slide', __link);
    this._thumbnails = GetBy.class('__thumbnail', __link);

    this._call = () => { this.loop() };

    this._slider = new SliderHorizontalScroll(__link, {
      itemClass: SliderScroll__Item,
      interaction: false,
      hasLimits: false,
    });

    const gui = new GUISlider(GetBy.id(__link.id + '_controls'), this._slider);
    GUIController.registerGUI(__link.id, gui);

    this.onShow = () => {
      this.showDefault();
      GUIController.show(this.item.id);
      gsap.ticker.add(this._call);
    };
    this.onHide = () => {
      this.hideDefault();
      GUIController.hide(this.item.id);
      gsap.ticker.remove(this._call);
    };
    this.onMove = () => {
      if (this.progressInside < 0 && Scroll.direction === 1) GUIController.hide(this.item.id);
      else if (this.progressInside < 1 && Scroll.direction === 1) GUIController.show(this.item.id);
      else if (this.progressInside > 1 && Scroll.direction === -1) GUIController.hide(this.item.id);
      else if (this.progressInside > 0 && Scroll.direction === -1) GUIController.show(this.item.id);
    };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  loop() {
    if (isSmartphone) return;

    this._slider.goto_percetage(1 - this.progressInside);
    this._slider.loop();

    const current = this._slider.actual;

    for (let i = 0; i < this._slides.length; i++) {
      if (i === current) {
        this._slides[i].classList.add('--active');
        if (this._thumbnails[i - 1]) this._thumbnails[i - 1].classList.add('--active');
      } else {
        this._slides[i].classList.remove('--active');
        if (this._thumbnails[i - 1]) this._thumbnails[i - 1].classList.remove('--active');
      }
    }
  }

  dispose() {
    super.dispose();
    this._slider.dispose();
  }
}

Scroll._registerClass('SectionBenefits', ScrollItem__SectionBenefits);

class SliderScroll__Item extends VScroll_Item {
  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.onShow = () => { };
    this.onHide = () => { };
    this.onMove = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  loop() { }
}
