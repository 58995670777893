import gsap, { Power2 } from 'gsap';

import { Basics } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { GUI } from '../components/GUIController';
import { Shffl } from './Shffl';

export class GUIPanel extends GUI {
    infoHolder;
    info;
    prevBtn;
    nextBtn;
    shffl = null;

    constructor(__item, __next, __prev) {
        super(__item);

        this.info = GetBy.class('__info', __item)[0];
        this.infoHolder = GetBy.class('__info-holder', __item)[0];

        this._prev = () => { __prev() };
        this.prevBtn = GetBy.selector('[data-prev]', __item)[0];
        this.prevBtn.addEventListener(Basics.clickEvent, this._prev);

        this._next = () => { __next() }
        this.nextBtn = GetBy.selector('[data-next]', __item)[0];
        this.nextBtn.addEventListener(Basics.clickEvent, this._next);

        this.directHide();
    }

    update(__val) {
        const template = `
            <div class="shffl">
                <span class="main">${__val}</span>
                <span class="effect" data-shffl data-exact-size>${__val}</span>
            </div>
        `;
        this.infoHolder.innerHTML = template;

        if (this.shffl) this.shffl.dispose();

        const shffl = GetBy.selector('[data-shffl]', this.infoHolder)[0];
        this.shffl = new Shffl(shffl, {
            origin: Shffl.LEFT,
            duration: Math.max(.6, Math.min(1.5, shffl.innerText.length / 25)),
            textFrom: '',
            textTo: shffl.innerText
        });
        this.shffl.play();
    }

    show() {
        if (this.visible) return;
        this.visible = true;

        gsap.killTweensOf([this.prevBtn, this.info, this.nextBtn]);
        gsap.to(this.prevBtn, { opacity: 1, duration: .2, delay: .1, ease: Power2.easeOut });
        gsap.to(this.info, { opacity: 1, duration: .2, delay: .2, ease: Power2.easeOut });
        gsap.to(this.nextBtn, { opacity: 1, duration: .2, delay: .3, ease: Power2.easeOut, onComplete: () => { this.afterShow(); } });
    }

    hide() {
        if (!this.visible) return;
        this.visible = false;

        this.beforeHide();

        gsap.killTweensOf([this.prevBtn, this.info, this.nextBtn]);
        gsap.to(this.prevBtn, { opacity: 0, duration: .1, ease: Power2.easeOut });
        gsap.to(this.info, { opacity: 0, duration: .1, delay: .1, ease: Power2.easeOut });
        gsap.to(this.nextBtn, { opacity: 0, duration: .1, delay: .2, ease: Power2.easeOut });
    }

    directHide() {
        super.directHide();
        gsap.killTweensOf([this.prevBtn, this.info, this.nextBtn]);
        gsap.set([this.info, this.prevBtn, this.nextBtn], { opacity: 0 });
    }

    dispose() {
        this.prevBtn.removeEventListener(Basics.clickEvent, this._prev);
        this.nextBtn.removeEventListener(Basics.clickEvent, this._next);
    }
}
