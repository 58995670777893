import { C, GetBy } from '../core/Element';
import Keyboard, { KEYS } from '../core/Keyboard';
import { Basics } from '../core/Basics';
import { Accessibility } from '../core/Accessibility';
import { Analytics } from '../core/Analytics';
import Cookies from '../utils/Cookies';
import { ControllerWindow } from './ControllerWindow';

export default class _Cmp {
  static STATE_OPEN = 'OPEN';
  static STATE_CLOSE = 'CLOSE';

  static container = GetBy.id('CMP');
  static infotab = GetBy.selector('[data-information]', this.container)[0];

  static _state = 'CLOSE';
  static _calls = {
    click: (e) => { this._click(e); }
  }

  static options = {
    level0: false,
    level1: false,
    level2: false,
    level3: false,
  }

  //==================================================================================================================
  //          GETTER SETTER
  //==================================================================================================================

  static get isOpen() { return this._state === _Cmp.STATE_OPEN; }

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  static init() {
    if (!Basics.hasCookies) {
      this.dispose();
      return;
    }

    this.container.setAttribute('aria-expanded', 'false');

    _Cmp.options = {
      level0: !!Cookies.get('cookie_policy'),
      level1: !!Cookies.get('cookie_level1'),
      level2: !!Cookies.get('cookie_level2'),
      level3: !!Cookies.get('cookie_level3'),
    }

    this.addToggles();

    this.setupChecks();

    if (this.options.level0) {
      this.activateCookies();
      this.dispose();
    } else {
      this.show();
    }
  }

  static addToggles() {
    [...GetBy.selector('[data-toggle-cmp]')].map(item => {
      item.addEventListener(Basics.clickEvent, () => { this.isOpen ? this.hide() : this.show() });
    });
  }

  static setup() {
    Accessibility.trap(this.container);
    Keyboard.add(KEYS.ESC, 'CookiesESC', () => { _Cmp.hide(); });
    this.container.addEventListener(Basics.clickEvent, this._calls.click);
  }

  static setupChecks() {
    [...GetBy.selector('[data-check]')].map(item => {
      if (this.options[item.dataset.check]) {
        item.classList.toggle('--active');
      }
    });
  }

  static _click(e) {
    const item = e.target;
    const data = item.dataset;

    switch (item.tagName.toLowerCase()) {
      case 'button':
        if (!!data.check) {
          item.classList.toggle('--active');
          this.options[data.check] = item.classList.contains('--active');
        }

        if (data.ok !== undefined) {
          this.activateCookies();
          this.hide();
        }

        if (data.all !== undefined) {
          this.options = {
            level0: true,
            level1: true,
            level2: true,
            level3: true
          }

          this.activateCookies();
          this.hide();
        }

        if (data.cancel !== undefined) {
          this.cancel();
        }

        if (data.advanced !== undefined) {
          const bol = this.infotab.getAttribute('aria-expanded') === 'true' ? false : true;
          this.infotab.setAttribute('aria-expanded', bol);
        }

        break;
    }
  }

  static activateCookies() {
    Cookies.add('cookie_policy', 'accepted');

    if (this.options.level1) {
      Cookies.add('cookie_level1', 'accepted');
    } else {
      Cookies.remove('cookie_level1')
    }

    if (this.options.level2) {
      Cookies.add('cookie_level2', 'accepted');
    } else {
      Cookies.remove('cookie_level2')
    }

    if (this.options.level3) {
      Cookies.add('cookie_level3', 'accepted');
    } else {
      Cookies.remove('cookie_level3')
    }

    [...GetBy.selector('[data-cookiecategory]')].map(item => {
      if (this.options[item.dataset.cookiecategory]) {
        if (item.getAttribute('data-src')) {
          item.setAttribute('src', item.getAttribute('data-src'));
          item.removeAttribute('data-src');
        } else {
          const parent = item.parentNode;
          item.setAttribute('type', 'text/javascript');

          Analytics.isGTM = Analytics.isGTM || item.text.indexOf('gtm') > -1;
          Analytics.isGA = Analytics.isGA || item.text.indexOf('ga') > -1;
          Analytics.isGTAG = Analytics.isGTAG || item.text.indexOf('gtag') > -1;
          Analytics.code = item.getAttribute('data-code') || Analytics.code;
          Analytics.isEnabled = Analytics.isGTM || Analytics.isGA || Analytics.isGTAG;

          C.remove(item);
          parent.appendChild(item);
        }

        item.removeAttribute('data-cookiecategory');
      }
    });
  }

  static ok(item) {
    Cookies.add('cookie_policy', 'accepted');
    Analytics.init();
    this.hide();
  }

  static cancel() {
    this.hide();
  }

  //SHOW
  static show(__d = 0) {
    this.setup();
    this._state = Cookies.STATE_OPEN;
    this.container.setAttribute('aria-expanded', 'true');
    this.show__effect();
  }

  static show__effect(__d = 0) {
    this.container.style.opacity = 1;
  }

  //HIDE
  static hide(__d = 0) {
    this._state = _Cmp.STATE_CLOSE;
    this.hide__effect();
  }

  static hide__effect(__d = 0) {
    this.dispose();
  }

  static dispose() {
    Accessibility.removeTrap();
    Keyboard.remove(KEYS.ESC, 'CookiesESC');
    this.container.removeEventListener(Basics.clickEvent, this._calls.click);
    this.container.setAttribute('aria-expanded', 'false');
  }
}
