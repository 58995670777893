import { GetBy } from '../_app/cuchillo/core/Element';
import { ROTATIONS } from '../3D/constants';
import Scene from './Scene';
import Particles from './Particles';

export default class AvatarController {
    static tick = 0;
    static avatar;
    static initialized = false;

    static set progress(__p) {
        this.avatar.progress = __p;
    }

    static set isLast(__b) {
        this.avatar.isLast = __b;
    }

    static set current(__c) {
        this.avatar.current = __c;
        this.avatar.rot = ROTATIONS[__c];
    }

    static set scalar(__s) {
        this.avatar.scalar = __s;
    }

    static set movement(__m) {
        this.avatar.movement = __m;
    }

    static get rotation() {
        return this.avatar.rotation;
    }

    static init() {
        if (this.initialized) return;

        const target = GetBy.selector('#scene-target .__target')[0];
        const s = target.offsetWidth;

        this.avatar = new Particles({
            size: s,
            target
        });

        Scene.group.add(this.avatar.container);

        this.avatar.init();
        this.avatar.active = true;

        this.initialized = true;
    }

    static start() { }

    static stop() { }

    static loop() {
        if (!this.initialized) return;

        this.tick++;
        if (this.tick % 2 === 0) this.avatar.update();
    }

    static resize() {
        if (!this.initialized) return;

        this.avatar.resize();
    }

    static dispose() {
        Scene.group.remove(this.avatar.container);
        this.avatar.dispose();
    }
}
