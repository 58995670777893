import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Basics } from '../_app/cuchillo/core/Basics';
import ScrollItem__Default from './ScrollItem__Default';

class ScrollItem__SectionLanding extends ScrollItem__Default {
  cta;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.cta = GetBy.class('__btn', __link)[0];
    this.cta.addEventListener(Basics.clickEvent, () => { Scroll.gotoAvPag() });

    // this.onShow = () => { };
    // this.onHide = () => { };
    // this.onMove = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
}

Scroll._registerClass('SectionLanding', ScrollItem__SectionLanding);
