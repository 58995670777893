export const GRADIENT_FRAGMENT = `
    precision mediump float;

    uniform vec3 color1; // Primer color
    uniform vec3 color2; // Segundo color
    uniform vec3 color3; // Tercer color
    uniform float time;  // Uniform de tiempo
    uniform float angle;  // Uniform de tiempo

    varying vec2 vUv;
    
    // DEGRADADO
    // void main() {
    //     // Crea un factor de desplazamiento basado en el tiempo
    //     float displacement = sin(time);

    //     // Aplica el desplazamiento a las coordenadas de textura vUv
    //     vec2 displacedUv = vUv + displacement; // Ajusta el valor de desplazamiento según tus necesidades

    //     // Interpola entre los colores originales basados en las coordenadas de textura desplazadas
    //     vec3 finalColor = mix(color1, color2, displacedUv.x) * (1.0 - displacedUv.y) + mix(color2, color3, displacedUv.x) * displacedUv.y;

    //     gl_FragColor = vec4(finalColor, 1.0);
    // }

    // Función de ruido Perlin 2D
    float noise(vec2 p) {
        return fract(sin(dot(p, vec2(12.9898, 78.233) + time)) * 43758.5453);
    }


    // GRANULADO
    // void main() {
    //     // Obtén el valor de ruido en las coordenadas de textura con desplazamiento en el tiempo
    //     float n = noise(vUv * 10.0);

    //     // Aplica un desplazamiento basado en el tiempo
    //     float displacement = sin(time) * 0.1; // Ajusta la amplitud y velocidad del desplazamiento

    //     // Mezcla los colores originales basados en el valor de ruido y el desplazamiento
    //     vec3 finalColor = mix(mix(color1, color2, n), color3, n + displacement);

    //     gl_FragColor = vec4(finalColor, 1.0);
    // }


    // CIRCULAR GRADIENT
    // void main() {
    //     // Calcula la distancia desde el centro de la onda (vUv - 0.5)
    //     float distanceFromCenter = length(vUv - 0.5);

    //     // Calcula un valor basado en sin(time) que cambia suavemente de -1 a 1
    //     float sinValue = sin(time);

    //     // Mapea el valor de sin(time) al rango de 0 a 1
    //     float mappedValue = (sinValue + 1.0) * 0.5;

    //     // Interpola suavemente entre color1 y color2 en función de mappedValue
    //     vec3 finalColor = mix(color1, color2, mappedValue * (1.0 - distanceFromCenter));

    //     // Aplica el color basado en la distancia desde el centro de la onda
    //     gl_FragColor = vec4(finalColor, 1.0);
    // }

    // FUNDIDO DE COLOR
    // void main() {
    //     // Calcula un valor basado en sin(time) que cambia de -1 a 1
    //     float sinValue = sin(time);

    //     // Mapea el valor de sin(time) al rango de 0 a 1 en función de la coordenada vertical vUv.y
    //     float mappedValue = (sinValue + 1.0) * 0.5;

    //     // Interpola suavemente entre color1 y color2 en función de mappedValue
    //     vec3 finalColor = mix(color1, color2, mappedValue);

    //     gl_FragColor = vec4(finalColor, 1.0);
    // }


    // MONOPO
    uniform float noiseIntensity;
    uniform float linesPattern1;
    uniform float linesPattern2;
    uniform float linesIntensity;
    varying vec3 vPosition;

    // Noise
    float mod289(float x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
    vec4 mod289(vec4 x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
    vec4 perm(vec4 x){return mod289(((x * 34.0) + 1.0) * x);}

    float noise(vec3 p){
        vec3 a = floor(p);
        vec3 d = p - a;
        d = d * d * (3.0 - 2.0 * d);

        vec4 b = a.xxyy + vec4(0.0, 1.0, 0.0, 1.0);
        vec4 k1 = perm(b.xyxy);
        vec4 k2 = perm(k1.xyxy + b.zzww);

        vec4 c = k2 + a.zzzz;
        vec4 k3 = perm(c);
        vec4 k4 = perm(c + 1.0);

        vec4 o1 = fract(k3 * (1.0 / 41.0));
        vec4 o2 = fract(k4 * (1.0 / 41.0));

        vec4 o3 = o2 * d.z + o1 * (1.0 - d.z);
        vec2 o4 = o3.yw * d.x + o3.xz * (1.0 - d.x);

        return o4.y * d.y + o4.x * (1.0 - d.y);
    }

    float lines(vec2 uv, float offset) {
        return smoothstep(
            0.,
            0.5 + offset * 0.5,
            0.5 * abs((sin(uv.x * linesIntensity) + offset * 2.))
        );
    }

    mat2 rotate2D(float angle) {
        return mat2(
            -cos(angle), -sin(angle),
            sin(angle), -cos(angle)
        );
    }

    void main() {
        float n = noise(vPosition + time * noiseIntensity);

        vec2 rotatedPosition = mat2(cos(angle), -sin(angle), sin(angle), cos(angle)) * vPosition.xy;
        vec2 baseUV = rotate2D(n) * rotatedPosition * 0.1;

        float pattern1 = lines(baseUV, linesPattern1);
        float pattern2 = lines(baseUV, linesPattern2);

        vec3 baseColor = mix(color1, color2, pattern1);
        vec3 secondColor = mix(baseColor, color3, pattern2);

        gl_FragColor = vec4(vec3(secondColor), 1.);
    }
`;
