import { ShaderMaterial, MeshBasicMaterial, TextureLoader, Vector3, SphereGeometry, BackSide, FrontSide, PlaneGeometry, Color, BoxGeometry, Object3D, DoubleSide, Mesh } from 'three';
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';

import { GRADIENT_FRAGMENT } from './shaders/gradient-fragment';
import { GRADIENT_VERTEX } from './shaders/gradient-vertex';
import { Metrics } from '../_app/cuchillo/core/Metrics';

export const POSITIONS = [
    {
        x: 0,
        y: 800,
        z: 0
    },
    {
        x: 0,
        y: 0,
        z: 0
    },
    {
        x: 0,
        y: 0,
        z: 1500
    },
    {
        x: -250,
        y: 50,
        z: 1100
    },
    {
        x: 0,
        y: 0,
        z: 300
    },
];

export const LOOKAT_POSITIONS = [
    {
        x: 0, y: 800, z: -100
    },
    {
        x: 0, y: 0, z: -100
    },
    {
        x: 0, y: 0, z: -100
    },
    {
        x: -250, y: 0, z: -100
    },
    {
        x: 0, y: 0, z: -100
    },
];

export const ROTATIONS = [
    {
        x: 0, y: 0, z: 0
    },
    {
        x: 0, y: 2 * Math.PI, z: 0
    },
    {
        x: 0, y: 4 * Math.PI, z: 0
    },
    {
        x: 0, y: 10 * Math.PI, z: 0
    }
];

export const SETTINGS = {
    opacity: 1,
    fov: 50,
    near: 20,
    far: 100000,
    cameraPos: new Vector3(POSITIONS[0].x, POSITIONS[0].y, POSITIONS[0].z),
    lookAt: new Vector3(LOOKAT_POSITIONS[0].x, LOOKAT_POSITIONS[0].y, LOOKAT_POSITIONS[0].z),
    // rotationLerp: .02,
    controls: false,
    emissive: '#000000',
    clearColor: '#02024c',
    color1: '#F9C9E1',
    color2: '#E1574D',
    color3: '#171E30',
    time: .5,
    noiseIntensity: 1.130,
    linesPattern1: 0,
    linesPattern2: .783,
    linesIntensity: 19.55,
    angle: 310,
    mouseX: 4.46,
    mouseY: 2.5,
    mouseLerp: .015,
    cameraLookAtLerp: .032,
    cameraLerp: .02,
    groupRotation: true,
    cameraRotation: false
}

export const BASIC_MATERIAL = new MeshBasicMaterial({ color: 0x0000FF, side: DoubleSide });
export const BOX_GEOMETRY = new BoxGeometry();
export const PLANE_GEOMETRY = new PlaneGeometry();
export const BG_GEOMETRY = new SphereGeometry();
export const BG_MATERIAL = new ShaderMaterial({
    uniforms: {
        time: { type: 'f', value: 0 },
        noiseIntensity: { type: 'f', value: SETTINGS.noiseIntensity },
        linesPattern1: { type: 'f', value: SETTINGS.linesPattern1 },
        linesPattern2: { type: 'f', value: SETTINGS.linesPattern2 },
        linesIntensity: { type: 'f', value: SETTINGS.linesIntensity },
        angle: { type: 'f', value: SETTINGS.angle },
        color1: { value: new Color(SETTINGS.color1) },
        color2: { value: new Color(SETTINGS.color2) },
        color3: { value: new Color(SETTINGS.color3) }
    },
    vertexShader: GRADIENT_VERTEX,
    fragmentShader: GRADIENT_FRAGMENT,
    side: BackSide
});
export const PLANE_MATERIAL = new ShaderMaterial({
    uniforms: {
        time: { type: 'f', value: 0 },
        noiseIntensity: { type: 'f', value: SETTINGS.noiseIntensity },
        linesPattern1: { type: 'f', value: SETTINGS.linesPattern1 },
        linesPattern2: { type: 'f', value: SETTINGS.linesPattern2 },
        linesIntensity: { type: 'f', value: SETTINGS.linesIntensity },
        color1: { value: new Color(SETTINGS.color1) },
        color2: { value: new Color(SETTINGS.color2) },
        color3: { value: new Color(SETTINGS.color3) }
    },
    vertexShader: GRADIENT_VERTEX,
    fragmentShader: GRADIENT_FRAGMENT,
    side: FrontSide
});

export const OBJECT_LOADER = new OBJLoader();
export const TEXTURE_LOADER = new TextureLoader();
export const CENTER = new Vector3();
export const DUMMY = new Object3D();

export const PARTICLE_GEOMETRY = new PlaneGeometry(1, 1);

export const SPHERE_GEOMETRY = new SphereGeometry();
export const SPHERE = new Mesh(SPHERE_GEOMETRY, BASIC_MATERIAL);

// export const PARTICLE_MATERIAL = new ShaderMaterial({
//     uniforms: {
//         texture1: { type: 't', value: undefined },
//         sprite: { type: 'f', value: 1.0 },
//         cols: { type: 'f', value: AVATAR_SETTINGS.spritesheetCols },
//         offsetSprite: { type: 'f', value: 1 / AVATAR_SETTINGS.spritesheetCols },
//         offsetPosition: { type: 'f', value: 1 / (AVATAR_SETTINGS.spritesheetCols - 1) },
//         burn: { type: 'f', value: 1.0 },
//         opacity: { type: 'f', value: 1.0 }
//     },
//     fragmentShader: SPRITESHEET_FRAGMENT,
//     vertexShader: IMAGE_VERTEXT,
//     depthTest: false,
//     transparent: false,
// });
