import { Basics, isTouch } from '../_app/cuchillo/core/Basics';
import { Shffl } from '../components/Shffl';
import { GetBy } from '../_app/cuchillo/core/Element';

export default class ScrollbarCircle {
  container = null;
  track = null;
  number = null;

  onChange = null;
  progress = 0;

  breadcrumbs = [];
  hovers = [];

  constructor(__container = GetBy.id('Scrollbar')) {
    this.container = __container;

    this.track = GetBy.selector('svg', this.container)[0];
    this.number = GetBy.class('__number', this.container)[0];

    this.update(0);

    this.setUpBreadcrumbs();
  }

  setUpBreadcrumbs() {
    // Create sections
    const breadcrumbs = GetBy.class('breadcrumbs', this.container)[0];
    const sections = GetBy.selector('[data-section]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];

      const a = document.createElement('a');
      a.innerHTML = section.innerHTML;
      a.href = '#' + section.dataset.section;
      a.dataset.index = i;
      a.dataset.shffl = Shffl.getRandText(6);

      breadcrumbs.appendChild(a);

      this.breadcrumbs.push(a);

      if (i < sections.length - 1) {
        const span = document.createElement('span');
        span.innerHTML = '//';
        breadcrumbs.appendChild(span);
      }
    }

    // Hovers
    this._hoverIn = (e) => this.hovers[e.target.dataset.index].play();
    this._hoverOut = (e) => this.hovers[e.target.dataset.index].reverse();

    for (let i = 0; i < this.breadcrumbs.length; i++) {
      const breadcrumb = this.breadcrumbs[i];

      const shffl = new Shffl(breadcrumb, {
        origin: Shffl.RANDOM,
        duration: Math.max(.6, Math.min(2, breadcrumb.innerText.length / 25)),
        textFrom: breadcrumb.dataset.shffl,
        textTo: breadcrumb.innerText
      });

      this.hovers.push(shffl);

      breadcrumb.addEventListener(Basics.mouseOver, this._hoverIn);
      breadcrumb.addEventListener(Basics.mouseOut, this._hoverOut);
    }
  }

  update(__progress) {
    this.progress = __progress;

    let p = __progress < .05 ? 0 : Math.ceil(__progress * 100)
    if (p === 0) p = '00' + p;
    else if (p < 100) p = '0' + p;

    this.number.innerHTML = p;
    this.container.style.setProperty('--progress', p);
  }

  end() {
    this.progress = 0;
  }

  resize() { }

  dispose() { }
}