import { gsap, Power3 } from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import Win from '../_app/cuchillo/windows/Window';

class Newsletter extends Win {
  constructor(__container) {
    super(__container, 'Newsletter');
    this.directHide();
  }

  show__effect(__d = 0) {
    gsap.set(this.container, { opacity: 1 });
    gsap.to(this.container, { opacity: 1, duration: .4, ease: Power3.easeOut, onComplete: () => { this.afterShow() } });
  }

  hide__effect(__d = 0) {
    gsap.to(this.container, { opacity: 0, duration: .4, ease: Power3.easeOut, onComplete: () => { this.afterHide(); } });
  }

  directHide() {
    gsap.set(this.container, { opacity: 0 });
    super.directHide();
  }
}

export const WinNewsletter = new Newsletter(GetBy.id('Newsletter'));

