import gsap, { Power1 } from "gsap";

import { GetBy } from "../_app/cuchillo/core/Element";
import { Shffl } from "../components/Shffl";
import { Maths } from "../_app/cuchillo/utils/Maths";

export default class Preloader {
    _container;
    static progress;
    static loading;
    static thumb;
    static number;
    static percentage = 0;
    static itemsShffl = [];

    static init() {
        this._container = GetBy.id('Preloader');

        this.progress = GetBy.class('progress', this._container)[0];
        this.loading = GetBy.class('loading', this._container)[0];

        gsap.to([this.progress, this.loading], { opacity: 1, duration: .2, delay: .2, ease: Power1.easeOut });

        this.number = GetBy.class('__number', this._container)[0];
        this.thumb = GetBy.class('__thumb', this._container)[0];

        [...GetBy.selector('[data-shffl]', this._container)].map(item => {
            const shffl = new Shffl(item, {
                origin: Shffl.RANDOM,
                duration: Math.max(1, Math.min(2, item.innerText.length / 25)),
                textFrom: '',
                textTo: item.innerText
            });

            this.itemsShffl.push(shffl);
        });

        for (let i = 0; i < this.itemsShffl.length; i++) {
            gsap.delayedCall(.4 * i, () => {
                this.itemsShffl[i].play();
            })
        }
    }

    static updateProgressBar() {
        const filledCount = Math.floor((this.percentage / 100) * this.thumb.textContent.length);
        const progressBarText = '='.repeat(filledCount) + '_'.repeat(this.thumb.textContent.length - filledCount);
        this.thumb.textContent = progressBarText;
    }

    static animateNumber(__call) {
        const finalValue = Math.min(100, Math.floor(Math.random() * 35) + this.percentage);
        const duration = (Math.random() * 500) / 1000;

        gsap.to(this, {
            percentage: finalValue,
            duration: duration,
            onUpdate: () => {
                let number = Math.ceil(this.percentage);
                if (number < 10) number = '00' + number;
                else if (number < 100) number = '0' + number;

                this.number.innerHTML = number;
                this.updateProgressBar();
            },
            onComplete: () => {
                if (this.percentage < 100) {
                    const time = (Math.random() * 2000 + 1000) / 1000;
                    setTimeout(() => { this.animateNumber(__call) }, time);
                } else {
                    __call();
                }
            }
        });
    }

    static update(__progress) { }

    static hide(__call) {
        const cb = () => {
            gsap.to([this.progress, this.loading], {
                opacity: 0,
                duration: .3,
                delay: .3,
                ease: Power1.easeOut
            });

            gsap.to(this._container, {
                duration: .8,
                opacity: 0,
                delay: .6,
                onComplete: () => {
                    this._container.style.display = "none";
                    if (__call) __call();
                }
            });
        }

        this.animateNumber(cb);
    }

    static directHide(__call) {
        gsap.to(this._container, {
            duration: 0,
            opacity: 0,
            delay: 0,
            onComplete: () => {
                this._container.style.display = "none";
                if (__call) __call();
            }
        });
    }
}
