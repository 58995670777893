import * as THREE from 'three';
import { Vector3, Object3D } from 'three';

import { MeshSurfaceSampler } from './utils/MeshSurfaceSampler';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { SPRITESHEET_FRAGMENT } from './shaders/particles-fragment';
import { IMAGE_VERTEXT } from './shaders/particles-vertex';
import SpriteSheetGenerator from './utils/SpriteSheetGenerator';
import { isDebug, isSmartphone } from '../_app/cuchillo/core/Basics';
import { DUMMY, OBJECT_LOADER, PARTICLE_GEOMETRY, SPHERE } from './constants';
import Perlin from './utils/Perlin';
import DebugPane from './DebugPane';
import POSITIONS_1 from './positions/positions-1.json';
import POSITIONS_2 from './positions/positions-2.json';
import POSITIONS_3 from './positions/positions-3.json';
import POSITIONS_4 from './positions/positions-4.json';

export default class Particles {
	tick = 0;
	tickAux = 0;
	progress = 0;
	current = 0;
	lerpMorph = 0;
	loaded = false;
	rot = new Vector3();
	rotation = new Vector3();
	container = new Object3D();
	samplers = [];
	positions = [];
	isLast = false;
	opts = {
		size: Metrics.parseSize('50vw')
	};
	defaults = {
		loadObj: false,
		spritesheetCols: 20,
		animation: {
			tick: 0,
			finePosition: 500,
			gridSize: 10,
			speed: 0.008
		},
		x: {
			force: 20,//119,
			amplitude: 511,
			period: 45109,
			z_dif: 0.22,
		},
		y: {
			force: 20, //54.3,
			amplitude: 413,
			period: 40000,
			z_dif: 0
		},
		z: {
			force: 20, //130,
			amplitude: 446,
			period: 37500,
			z_dif: 0.152,
		},
		scale: {
			force: 0.001, //28,
			amplitude: 298,
			period: 40000,
			z_dif: 0.163,
		},
		particles: {
			total: 2500,
			size: isSmartphone ? Metrics.parseSize('8vw') : Metrics.parseSize('1vw')
		},
		container: {
			scale: this.size,
			avatarVisible: false,
			scalar: 1,
			movement: 1,
			scaleZ: 0 //3,
		}
	}

	objUrls = ['/assets/models/1.obj', '/assets/models/2.obj', '/assets/models/3.obj'];
	avatarMesh = [];
	mesh;
	points = [];
	noise = new Perlin(Math.random());

	get size() {
		return this.opts.size;
	}

	set scalar(__s) {
		this.defaults.container.scalar = __s;
	}

	set movement(__m) {
		this.defaults.container.movement = __m;
	}

	constructor(opts = {}) {
		this.opts = opts;

		this.samplers[0] = POSITIONS_1;
		this.samplers[1] = POSITIONS_2;
		this.samplers[2] = POSITIONS_3;
		this.samplers[3] = POSITIONS_4;
	}

	init() {
		const cb = () => {
			this.initPoints();
			this.setupPoints();
			this.initGeometry();
		};

		if (this.defaults.loadObj) {
			this.loadObjects(cb);
		} else {
			cb();
			this.loaded = true;
		}

		if (isDebug) {
			DebugPane.init(this);
		}
	}

	loadObjects(__call) {
		let loaded = 0;

		for (let i = 0; i < this.objUrls.length; i++) {
			OBJECT_LOADER.load(
				this.objUrls[i],
				(object) => {
					object.traverse((child) => {
						if (child.isMesh) {
							this.avatarMesh[i] = child;
							this.avatarMesh[i].scale.x = this.defaults.container.scale;
							this.avatarMesh[i].scale.y = this.defaults.container.scale;
						}
					});

					loaded++;
					if (__call && loaded === this.objUrls.length) {
						this.loaded = true;
						setTimeout(() => __call(), 100);
					}
				},
				function (xhr) { },
				function (error) { }
			);
		}

		this.avatarMesh[this.objUrls.length] = SPHERE;
		console.log(this.avatarMesh);
	}

	reset() {
		if (!this.loaded) return;

		this.dispose();
		this.initPoints();
		this.setupPoints();
		this.initGeometry();
	}

	initPoints() {
		for (let j = 0; j <= this.objUrls.length; j++) {
			let points = [];
			let sampler;

			if (this.defaults.loadObj) sampler = new MeshSurfaceSampler(this.avatarMesh[j]).build();

			for (let i = 0; i < this.defaults.particles.total; i++) {
				let position = new THREE.Vector3();
				const index = Maths.maxminRandom(IMAGES_AVATARS[j].length, 1);
				const image = IMAGES_AVATARS[j][index - 1];
				const item = {
					index: index,
					movable: Math.random() > -.6,
					scaleX: image.width > image.height ? 1 : image.width / image.height,
					scaleY: image.width < image.height ? 1 : image.height / image.width,
					scaleMod: 1,
					scaleNoiseMod: 1,
					scaleMax: 1,
				}

				if (this.defaults.loadObj) sampler.sample(position);
				else position.copy(this.samplers[j][i]);

				points.push({
					...item,
					...position
				});
			}

			this.points.push(points);
		}
	}

	setupPoints() {
		// Setting up original points
		this.points.map(points => {
			const json = [];

			for (let i = 0; i < this.defaults.particles.total; i++) {
				if (this.defaults.loadObj) {
					json.push({
						x: points[i].x.toFixed(2),
						y: points[i].y.toFixed(2),
						z: points[i].z.toFixed(2)
					});
				}

				points[i].x *= this.defaults.container.scale;
				points[i].y *= this.defaults.container.scale;
				points[i].z *= this.defaults.container.scale;

			}

			if (this.defaults.loadObj) console.log(json);

			points.sort((a, b) => a.z - b.z);
		});

		// Creating position points from first avatar
		this.points[0].map(point => {
			this.positions.push(new Vector3(point.x, point.y, point.z));
		});
	}

	initGeometry() {
		/* MATERIAL */
		const uniforms = {
			texture1: { type: 't', value: SpriteSheetGenerator.textures[0] },
			texture2: { type: 't', value: SpriteSheetGenerator.textures[1] },
			texture3: { type: 't', value: SpriteSheetGenerator.textures[2] },
			progress: { type: 'f', value: this.progress },
			sprite: { type: 'f', value: 1.0 },
			cols: { type: 'f', value: this.defaults.spritesheetCols },
			offsetSprite: { type: 'f', value: 1 / this.defaults.spritesheetCols },
			offsetPosition: { type: 'f', value: 1 / (this.defaults.spritesheetCols - 1) },
			opacity: { type: 'f', value: 1.0 }
		};

		const material = new THREE.ShaderMaterial({
			uniforms,
			fragmentShader: SPRITESHEET_FRAGMENT,
			vertexShader: IMAGE_VERTEXT,
			depthTest: false,
			transparent: false,
			side: THREE.DoubleSide
		});

		/* MESH */
		const sprites = [];

		this.mesh = new THREE.InstancedMesh(PARTICLE_GEOMETRY, material, this.defaults.particles.total);
		this.mesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage);

		for (let i = 0; i < this.defaults.particles.total; i++) {
			sprites.push(this.points[this.current][i].index);

			DUMMY.scale.set(this.points[this.current][i].scaleX * this.defaults.particles.size, this.points[this.current][i].scaleY * this.defaults.particles.size, 1)
			DUMMY.position.set(this.points[this.current][i].x, this.points[this.current][i].y, this.points[this.current][i].z);
			DUMMY.updateMatrix();

			this.mesh.setMatrixAt(i, DUMMY.matrix);
		}

		this.mesh.geometry.setAttribute('nSprite', new THREE.InstancedBufferAttribute(new Float32Array(sprites), 1, false));
		this.mesh.instanceMatrix.needsUpdate = true;

		this.container.add(this.mesh);
	}

	// ---------------------------------------------------------------------------------------------
	// PUBLIC
	// ---------------------------------------------------------------------------------------------
	update() {
		this.tick += this.defaults.animation.speed;
		this.defaults.animation.tick = this.tick;
		this.defaults.animation.finePosition = 0;

		if (!this.mesh) return;

		const current = this.isLast ? this.current + 1 : this.current;
		const POSITION = { x: 0, y: 0, z: 0 };
		const SCALE = { x: 0, y: 0, z: 1 };

		for (let i = 0; i < this.defaults.particles.total; i++) {
			// POSITION
			POSITION.x = this.points[current][i].x;
			POSITION.y = this.points[current][i].y;
			POSITION.z = this.points[current][i].z;

			POSITION.x += this.noise.simplex3(
				POSITION.x / this.defaults.x.amplitude + this.defaults.x.period + POSITION.z * this.defaults.x.z_dif,
				POSITION.y / this.defaults.x.amplitude + this.defaults.x.period + POSITION.z * this.defaults.x.z_dif,
				this.tick) * this.defaults.x.force * this.defaults.container.movement;

			POSITION.y += this.noise.simplex3(
				POSITION.x / this.defaults.y.amplitude + this.defaults.y.period + POSITION.z * this.defaults.y.z_dif,
				POSITION.y / this.defaults.y.amplitude + this.defaults.y.period + POSITION.z * this.defaults.y.z_dif,
				this.tick) * this.defaults.y.force * this.defaults.container.movement;

			// SCALE
			this.points[current][i].scaleMod = Math.max(this.points[current][i].scaleMod - .2, 1);
			this.points[current][i].scaleNoiseMod = Math.min(this.points[current][i].scaleNoiseMod + .01, 1);

			const tempScale = this.noise.simplex3(
				this.points[current][i].x / this.defaults.scale.amplitude + this.defaults.scale.period,
				this.points[current][i].y / this.defaults.scale.amplitude + this.defaults.scale.period,
				this.tick) * (this.defaults.scale.force * this.points[current][i].scaleNoiseMod
				);

			SCALE.x = (this.points[current][i].scaleX * this.defaults.particles.size + tempScale) * this.points[current][i].scaleMod * this.defaults.container.scalar;
			SCALE.y = (this.points[current][i].scaleY * this.defaults.particles.size + tempScale) * this.points[current][i].scaleMod * this.defaults.container.scalar;

			// DUMMY STUFF
			this.positions[i].lerp(POSITION, 0.8);

			DUMMY.scale.set(SCALE.x, SCALE.y, SCALE.z)
			DUMMY.position.set(this.positions[i].x, this.positions[i].y, this.positions[i].z);
			DUMMY.updateMatrix();

			this.mesh.setMatrixAt(i, DUMMY.matrix);
		}

		// ROTATION
		this.rotation = this.rotation.lerp(this.rot, 0.056);
		this.container.rotation.x = this.rotation.x;
		this.container.rotation.y = this.rotation.y;
		this.container.rotation.z = this.rotation.z;

		// PROGRESS
		this.mesh.material.uniforms.progress.value = this.progress;

		this.mesh.instanceMatrix.needsUpdate = true;
	}

	show() { }

	hide() { }

	dispose() {
		if (!this.mesh) return;

		this.container.remove(this.mesh);
		this.mesh.geometry.dispose();
		this.mesh.material.dispose();
		this.mesh = null;

		this.points = [];
	}

	resize() {
		this.defaults.particles.size = Metrics.parseSize('1vw');
		this.defaults.container.scale = this.size;

		this.reset();
	}
}
