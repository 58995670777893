import { isDebug } from '../_app/cuchillo/core/Basics';

import { LOOKAT_POSITIONS, POSITIONS, SETTINGS } from './constants';
import SeedtagSketch from './SeedtagSketch';

export default class Scene {
    _scene;
    static seed = 0.5;

    static get scene() {
        return this._scene.scene;
    }

    static get group() {
        return this._scene.group;
    }

    static init() {
        this._scene = new SeedtagSketch({
            container: 'scene-three',
            debug: isDebug,
            transparent: true,
            clearColorAlpha: 0,
            fov: SETTINGS.fov,
            cameraPos: SETTINGS.cameraPos,
            lookAt: SETTINGS.lookAt,
            galleryCamera: SETTINGS.galleryCamera,
            cameraQuaternion: SETTINGS.cameraQuaternion,
            controls: SETTINGS.controls,
            far: SETTINGS.far,
            near: SETTINGS.near,
            clearColor: SETTINGS.clearColor
        });
    }

    static getCamera() {
        return this._scene.cameraGroup;
    }

    static setCameraPos(pos, lookAt) {
        this._scene.cameraPosition.copy(pos);
        if (lookAt) this._scene.cameraLookAtTarget.copy(lookAt);
    }

    static getCameraPos() {
        return this._scene.cameraGroup.position;
    }

    static toggleSection(__n) {
        // console.log('--- Toggle Position ---', __n);
        this.setCameraPos(POSITIONS[__n], LOOKAT_POSITIONS[__n]);
    }

    static start() {
        this._scene.start();
    }

    static stop() {
        this._scene.pause();
    }

    static loop() {
        this._scene.loop();
    }

    static resize() {
        this._scene.resize();
    }

    static getTime() {
        return this._scene.time;
    }

    static newSeed() {
        this.seed += 0.15;
    }
}
